import { enercityEndpoints } from '../../config/endpoints';
import {
  LoginSplitterResponseKey,
  LoginSplitterResponseTarget,
  LoginSplitterResponseTargetWithFallback,
} from '../../helpers/fetchLoginSplitterResponse';

const addFallbackToTarget = (
  target: LoginSplitterResponseTarget
): LoginSplitterResponseTargetWithFallback => ({
  ...target,
  fallbackUrl:
    target.key === LoginSplitterResponseKey.EnercityPortal
      ? enercityEndpoints.enercityPortal
      : enercityEndpoints.servicePortal,
});

export default addFallbackToTarget;
