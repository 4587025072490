import GenericModals from '@/components/GenericModals/GenericModals';
import LoginModal from '@/components/LoginModal/LoginModal';
import Page from '@/components/Page/Page';
import extractPageMetaFromRouteData from '@/helpers/extractPageMetaFromRouteData';
import { useInitialPageDataLayer } from '@/hooks/useInitialPageDataLayer/useInitialPageDataLayer';
import { CmsComponentsContext } from '../CmsComponentsContext';
import cmsComponents from './LoginPage.cmsComponents';
import type { LoginPageRouteData } from './interfaces';

const LoginPage = (routeData: LoginPageRouteData): JSX.Element => {
  const meta = extractPageMetaFromRouteData(routeData);
  const {
    props: { title, noindex, description, canonical, ...loginPageProps },
  } = routeData;

  useInitialPageDataLayer();

  return (
    <CmsComponentsContext.Provider value={cmsComponents}>
      <Page meta={meta}>
        <LoginModal {...loginPageProps} />
      </Page>
      <GenericModals modals={routeData.modals} />
    </CmsComponentsContext.Provider>
  );
};

export default LoginPage;
