import getParentPath from '@/helpers/getParentPath';
import { useCallback } from 'react';
import useNavigate from '../useNavigate/useNavigate';
import usePathname from '../usePathname/usePathname';
import useSafeRouter from '../useSafeRouter/useSafeRouter';

const useNavigateBack = () => {
  const router = useSafeRouter();
  const navigate = useNavigate();
  const pathname = usePathname();
  const parentPagePath = getParentPath(pathname);

  return useCallback(() => {
    if (window.history.length >= 3) {
      router.back();
    } else {
      navigate(parentPagePath);
    }
  }, [navigate, parentPagePath, router]);
};

export default useNavigateBack;
