import React from 'react';
import { LoginSplitterResponseKey } from '@/helpers/fetchLoginSplitterResponse';
import Button from '../Button/Button';
import { ButtonAlign, ButtonWidth } from '../Button/consts';
import { ClickableAction } from '../Clickable/consts';
import { HeadlineColor } from '../Headline/consts';
import Headline from '../Headline/Headline';
import RichText from '../RichText/RichText';
import type { LoginSeveralAccountsProps } from './interfaces';
import styles from './LoginSeveralAccounts.module.scss';
import makeFormPost from '@/helpers/makeFormPost';

const LoginSeveralAccounts = ({
  mail,
  targets,
  description,
  additionalInfo,
  headline,
  titleSP,
  descriptionSP,
  buttonTextSP,
  titleCP,
  descriptionCP,
  buttonTextCP,
}: LoginSeveralAccountsProps) => {
  return (
    <div className={styles.base}>
      <Headline
        headlineColor={HeadlineColor.Gradient}
        level={2}
        className={styles.headline}
      >
        {headline}
      </Headline>
      <span className={styles.mail}>{mail}</span>
      <RichText className={styles.text}>
        <p>{description}</p>
      </RichText>
      <div className={styles.teaserBox}>
        {targets.map((target) => {
          const isEnercityPortal =
            target.key === LoginSplitterResponseKey.EnercityPortal;
          const tileHeadline = isEnercityPortal ? titleCP : titleSP;
          const description = isEnercityPortal ? descriptionCP : descriptionSP;
          const buttonLabel = isEnercityPortal ? buttonTextCP : buttonTextSP;
          const targetURL = isEnercityPortal
            ? `${target.url}?showLogin=true`
            : `${target.url}`;
          const isServicePortal =
            target.key === LoginSplitterResponseKey.ServicePortal;

          const Card = isServicePortal ? 'div' : 'a';

          return (
            <Card
              className={styles.teaserTileBox}
              key={target.key}
              onClick={() => {
                if (isServicePortal) {
                  makeFormPost(target.url, {
                    login: mail,
                  });
                }
              }}
              href={!isServicePortal ? targetURL : ''}
            >
              <h3 className={styles.boxHeadline}>{tileHeadline}</h3>
              <span className={styles.boxText}>{description}</span>
              <div className={styles.boxButton}>
                <Button
                  align={ButtonAlign.Center}
                  width={ButtonWidth.Full}
                  actionType={ClickableAction.None}
                  className={styles.button}
                  clickContext={tileHeadline}
                  asButton
                >
                  {buttonLabel}
                </Button>
              </div>
            </Card>
          );
        })}
      </div>
      <RichText className={styles.infoText}>
        <p>{additionalInfo}</p>
      </RichText>
    </div>
  );
};

export default LoginSeveralAccounts;
