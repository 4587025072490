import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import useMeasure from '../../../hooks/useMeasure/useMeasure';
import Button from '../Button/Button';
import {
  ButtonAlign,
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonWidth,
} from '../Button/consts';
import ButtonLink from '../ButtonLink/ButtonLink';
import Clickable from '../Clickable/Clickable';
import { ClickableAction } from '../Clickable/consts';
import Divider from '../Divider/Divider';
import ErrorMessageBanner from '../ErrorMessageBanner/ErrorMessageBanner';
import FormField from '../FormField/FormField';
import { FormFieldType } from '../FormField/consts';
import FormFieldPassword from '../FormFieldPassword/FormFieldPassword';
import Headline from '../Headline/Headline';
import { HeadlineColor } from '../Headline/consts';
import RichText from '../RichText/RichText';
import styles from './Login.module.scss';
import { LoginStep } from './consts';
import type { LoginProps } from './interfaces';

const Login = ({
  handleChange,
  handleBlur,
  formState,
  getErrorByFieldName,
  handleSubmit,
  isFormValid,
  isFieldValid,
  setFieldValue,
  headline,
  subtitle,
  description,
  linkLabel,
  modallink,
  buttonLoginLabel,
  buttonLabel,
  linkButton,
  showMailError,
  errorMessage,
}: LoginProps) => {
  const [step, setStep] = useState(LoginStep.EMail);
  const isStepPassword = step === LoginStep.Password;

  const heightRef = useRef<HTMLDivElement>();
  const { height } = useMeasure(heightRef);

  const passwordAnimationProps = useSpring({
    from: {
      height: 0,
      opacity: 0,
      transform: `translateY(-16px)`,
    },
    to: {
      height: isStepPassword ? height : 0,
      opacity: isStepPassword ? 1 : 0,
      transform: isStepPassword ? `translateY(0)` : `translateY(-16px)`,
      pointerEvents: isStepPassword ? `all` : `none`,
    },
  });

  const handelNextStep = ($event: React.FormEvent<HTMLFormElement>) => {
    $event.preventDefault();

    if (isFieldValid?.('email', true) && step === LoginStep.EMail) {
      setStep(() => LoginStep.Password);
      setFieldValue('email', formState['email'].trim());
    }

    if (step === LoginStep.Password) {
      handleSubmit($event);
    }
  };

  return (
    <>
      <div className={clsx(styles.flexCenter)}>
        <form
          onSubmit={handelNextStep}
          className={clsx(styles.form, styles.flexCenter)}
        >
          <Headline
            headlineColor={HeadlineColor.Gradient}
            level={2}
            subText={subtitle}
            className={styles.loginHeadline}
          >
            {headline}
          </Headline>
          {showMailError && (
            <ErrorMessageBanner
              errorMessage={errorMessage || ''}
              className={styles.infoBanner}
            />
          )}

          <FormField
            label="E-Mail oder Benutzername"
            name={'email'}
            autoComplete="email"
            value={formState['email'] || ''}
            type={FormFieldType.Text}
            errorMessage={getErrorByFieldName?.(`email`)}
            onChange={handleChange}
            onBlur={handleBlur}
            className={styles.formfield}
            disabled={isStepPassword}
            icon={isStepPassword ? 'user/pen' : undefined}
            iconSize={isStepPassword ? 'iconSize24' : undefined}
            onClickIcon={() => {
              if (isStepPassword) {
                setStep(() => LoginStep.EMail);
                setFieldValue('password', '');
              }
            }}
            errorMessageHasRelativePosition
          />

          <animated.div style={passwordAnimationProps}>
            <div ref={heightRef as React.MutableRefObject<HTMLDivElement>}>
              <div className={styles.formfieldPassword}>
                {isStepPassword && (
                  <FormFieldPassword
                    label="Passwort"
                    autoComplete="current-password"
                    name={'password'}
                    value={formState['password'] || ''}
                    errorMessage={getErrorByFieldName?.(`password`)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={clsx(styles.formfield)}
                    autoFocus
                    errorMessageHasRelativePosition
                  />
                )}
              </div>
            </div>
          </animated.div>

          <Clickable
            actionType={ClickableAction.Modal}
            modallink={modallink}
            className={styles.link}
          >
            {linkLabel}
          </Clickable>

          <Button
            color={ButtonColor.Primary}
            size={ButtonSize.Medium}
            width={ButtonWidth.Auto}
            type={ButtonType.Submit}
            align={ButtonAlign.Center}
            inactive={isStepPassword ? !isFormValid : !isFieldValid?.('email')}
            className={styles.button}
            actionType={ClickableAction.None}
            asButton
          >
            {step === 0 ? 'Weiter' : `${buttonLoginLabel}`}
          </Button>
        </form>

        <Divider dividerText={'Oder'} className={styles.divider} />

        <RichText className={styles.text}>
          <span>{description}</span>
        </RichText>

        <ButtonLink
          actionType={ClickableAction.Linkextern}
          linkextern={linkButton}
          className={styles.register}
          align={ButtonAlign.Center}
        >
          {buttonLabel}
        </ButtonLink>
      </div>
    </>
  );
};

export default Login;
