import * as Yup from 'yup';

const schema = Yup.object({
  email: Yup.string()
    .required('Wir benötigen deine E-Mail-Adresse / Benutzername.')
    .min(3, 'Die E-Mail-Adresse oder Benutzername ist zu kurz.'),
  password: Yup.string()
    .required('Wir benötigen dein Passwort.')
    .min(3, 'Das angegebene Passwort ist zu kurz'),
});

export default schema;
