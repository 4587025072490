import { enercityEndpoints } from '../config/endpoints';

export enum LoginSplitterAction {
  Login = 'login',
  Register = 'register',
  Activate = 'activate',
}

export interface LoginBody {
  userId: string;
  password: string;
}

export interface RegisterBody {
  account_id: string;
  meter_id: string;
}
export interface ActivateBody {
  accept_terms_and_conditions: boolean;
  email: string;
  opt_in: boolean;
  session: string;
}

export enum LoginSplitterResponseAction {
  ChoosePortal = 'CHOOSE_PORTAL',
  PostTo = 'POST_TO',
  AuthenticationFailed = 'AUTHENTICATION_FAILED',
  PasswordSettingInitiated = 'PASSWORD_SETTING_INITIATED',
  Login = 'LOGIN',
  Activate = 'ACTIVATE',
  AccountReady = 'ACCOUNT_READY',
  EmailNotAvailable = 'EMAIL_NOT_AVAILABLE',
}

export enum LoginSplitterResponseKey {
  ServicePortal = 'SP',
  EnercityPortal = 'CP',
  LoginSplitter = 'LS',
}

export interface LoginSplitterResponseTarget {
  url: string;
  key: LoginSplitterResponseKey;
  description: string;
}

export interface LoginSplitterResponse {
  action: LoginSplitterResponseAction;
  targets: LoginSplitterResponseTarget[];
}

export interface RegisterResponse extends LoginSplitterResponse {
  session: string;
}
export interface LoginSplitterResponseTargetWithFallback
  extends LoginSplitterResponseTarget {
  fallbackUrl: string;
}

async function fetchLoginSplitterResponse(
  action: LoginSplitterAction.Login,
  body: LoginBody
): Promise<LoginSplitterResponse>;

async function fetchLoginSplitterResponse(
  action: LoginSplitterAction.Register,
  body: RegisterBody
): Promise<RegisterResponse>;

async function fetchLoginSplitterResponse(
  action: LoginSplitterAction.Activate,
  body: ActivateBody
): Promise<LoginSplitterResponse>;

async function fetchLoginSplitterResponse(
  action: LoginSplitterAction,
  body: LoginBody | RegisterBody | ActivateBody
): Promise<LoginSplitterResponse> {
  const endpoint = `${enercityEndpoints.loginSplitter}/${action}`;

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  return await response.json();
}

export default fetchLoginSplitterResponse;
