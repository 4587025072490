function getParentPath(path: string) {
  const lastSlash = path.lastIndexOf('/');
  if (lastSlash === -1) {
    return path;
  }
  if (lastSlash === 0) {
    return '/';
  }
  return path.substring(0, lastSlash);
}

export default getParentPath;
