import { BoxWidth } from '@uikit/components/Box/consts';
import Button from '@uikit/components/Button/Button';
import {
  ButtonAlign,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@uikit/components/Button/consts';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import Container from '@uikit/components/Container/Container';
import { HeadlineColor } from '@uikit/components/Headline/consts';
import Headline from '@uikit/components/Headline/Headline';
import Icon from '@uikit/components/Icon/Icon';
import ModalContent from '@uikit/components/Modal/ModalContent';
import RichText from '@uikit/components/RichText/RichText';
import React from 'react';
import type { RegisterModalRearLayerProps } from './interfaces';

const RegisterModalRearLayer = ({
  icon,
  text,
  buttonLabel,
  buttonLink,
  headline,
}: RegisterModalRearLayerProps) => {
  return (
    <ModalContent background="none" width={BoxWidth.Medium}>
      <div>
        {icon && (
          <RichText variant="default-white">
            <p style={{ textAlign: 'center' }}>
              <Icon variant={icon} size="iconSize64" isOutlined />
            </p>
          </RichText>
        )}

        <Headline headlineColor={HeadlineColor.Light}>{headline}</Headline>
        {text && (
          <RichText variant="default-white">
            <p style={{ textAlign: 'center' }}>{text}</p>
          </RichText>
        )}
      </div>

      {buttonLabel && buttonLink && (
        <Container spaceTop={3}>
          <Button
            actionType={ClickableAction.Linkintern}
            size={ButtonSize.Medium}
            width={ButtonWidth.Auto}
            align={ButtonAlign.Center}
            color={ButtonColor.Secondary}
            linkintern={buttonLink}
          >
            {buttonLabel}
          </Button>
        </Container>
      )}
    </ModalContent>
  );
};

export default RegisterModalRearLayer;
