import fetchLoginSplitterResponse, {
  LoginSplitterAction,
  LoginSplitterResponseAction,
  LoginSplitterResponseKey,
} from '@/helpers/fetchLoginSplitterResponse';
import makeFormPost from '@/helpers/makeFormPost';
import useForm from '@/hooks/useForm/useForm';
import useNavigateBack from '@/hooks/useNavigateBack/useNavigateBack';
import { useQueryParams } from '@/hooks/useQueryParams/useQueryParams';
import { BoxWidth } from '@uikit/components/Box/consts';
import Login from '@uikit/components/Login/Login';
import LoginSeveralAccounts from '@uikit/components/LoginSeveralAccounts/LoginSeveralAccounts';
import Modal from '@uikit/components/Modal/Modal';
import ModalContent from '@uikit/components/Modal/ModalContent';
import React, { useCallback, useState } from 'react';
import ModalPortal from '../ModalPortal/ModalPortal';
import RegisterModalRearLayer from '../RegisterModalRearLayer/RegisterModalRearLayer';
import addFallbackToTarget from './addFallbackToTarget';
import type { LoginModalProps } from './interfaces';
import schema from './schema';

const initialState = {
  email: '',
  password: '',
};


export const DEFAULT_ERROR_MESSAGE =
  'E-Mail ist nicht für das enercity Portal registriert. Bitte überprüfen Sie Ihre Eingaben.';

const LoginModal = ({
  mainHeadline,
  mainSubtitle,
  mainDescription,
  mainLinkLabel,
  modallink,
  mainButtonLoginLabel,
  mainButtonLabel,
  mainButtonLink,
  mainButtonLinkInternal,
  mainButtonLinkExternal,
  multipleAccountsAdditionalInfo,
  multipleAccountsDescription,
  multipleAccountsHeadline,
  multipleAccountsCardTitleCP,
  multipleAccountsCardDescriptionCP,
  multipleAccountsCardButtonTextCP,
  multipleAccountsCardTitleSP,
  multipleAccountsCardDescriptionSP,
  multipleAccountsCardButtonTextSP,
  welcomeHeadline,
  welcomeText,
  welcomeIcon,
  welcomeButtonLabel,
  welcomeButtonLink,
}: LoginModalProps) => {
  const handleModalClose = useNavigateBack();

  const useFormProps = useForm(() => void 0, schema, initialState);
  const [showServeralAccountsModal, setShowServeralAccountsModal] =
    useState(false);
  const [showConfirmEMailModal, setShowConfirmEMailModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState(
    DEFAULT_ERROR_MESSAGE
  );
  const [targets, setTargets] = useState([
    { url: '', description: '', key: LoginSplitterResponseKey.EnercityPortal },
  ]);
  const { params } = useQueryParams();

  const handleServeralAccountsModalClose = () => {
    handleModalClose();
    setShowServeralAccountsModal(false);
  };

  const handleSubmit = async ($event: React.FormEvent<HTMLFormElement>) => {
    $event.preventDefault();

    if (useFormProps.isFormValid) {
      try {
        const response = await fetchLoginSplitterResponse(
          LoginSplitterAction.Login,
          {
            userId: useFormProps.formState['email'],
            password: useFormProps.formState['password'],
          }
        );

        if (response.action === LoginSplitterResponseAction.ChoosePortal) {
          setShowServeralAccountsModal(true);
          setTargets(response.targets);
        } else if (response.action === LoginSplitterResponseAction.PostTo) {
          response.targets.forEach(async (target) => {
            const login =
              target.key === LoginSplitterResponseKey.ServicePortal
                ? 'login'
                : 'username';

            const formData = {
              [login]: useFormProps.formState['email'],
              password: useFormProps.formState['password'],
            };
            if (params.returnUrl) {
              formData.returnUrl = params.returnUrl;
            }

            makeFormPost(target.url, formData);
          });
        } else if (
          response.action ===
          LoginSplitterResponseAction.PasswordSettingInitiated
        ) {
          setShowConfirmEMailModal(true);
        } else if (
          response.action === LoginSplitterResponseAction.AuthenticationFailed
        ) {
          setModalErrorMessage(DEFAULT_ERROR_MESSAGE);
          setShowErrorModal(true);
        }
      } catch (error) {
        setModalErrorMessage(
          'Ups, ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
        );
        setShowErrorModal(true);
      }
    }
  };

  useFormProps.handleChange = ($event) => {
    useFormProps.setFieldValue($event.target.name, $event.target.value, true);
    setShowErrorModal(false);
    setModalErrorMessage(DEFAULT_ERROR_MESSAGE);
  };

  const ModalRearLayer = () => {
    return (
      <>
        {showConfirmEMailModal && (
          <RegisterModalRearLayer
            icon={welcomeIcon}
            headline={welcomeHeadline}
            text={welcomeText}
            buttonLabel={welcomeButtonLabel}
            buttonLink={welcomeButtonLink}
          />
        )}
      </>
    );
  };

  const getMainButtonLink = useCallback(() => {
    switch (mainButtonLink) {
      case 'Internal':
        return mainButtonLinkInternal;
      case 'External':
        return mainButtonLinkExternal;
      default:
        return mainButtonLink;
    }
  }, [mainButtonLink, mainButtonLinkInternal, mainButtonLinkExternal]);

  return (
    <>
      <Modal
        onClose={handleModalClose}
        rearLayer={<ModalRearLayer />}
        mainLayerVisible={!showConfirmEMailModal}
      >
        <ModalContent width={BoxWidth.Medium}>
          <Login
            {...useFormProps}
            handleSubmit={handleSubmit}
            headline={mainHeadline}
            subtitle={mainSubtitle}
            description={mainDescription}
            linkLabel={mainLinkLabel}
            modallink={modallink}
            buttonLoginLabel={mainButtonLoginLabel}
            buttonLabel={mainButtonLabel}
            linkButton={getMainButtonLink()}
            showMailError={showErrorModal}
            errorMessage={modalErrorMessage}
          />
        </ModalContent>
      </Modal>

      <ModalPortal
        id="modal-login-several-accounts"
        show={showServeralAccountsModal}
        onClose={handleServeralAccountsModalClose}
      >
        <ModalContent width={BoxWidth.Medium}>
          <LoginSeveralAccounts
            mail={useFormProps.formState['email']}
            targets={targets.map(addFallbackToTarget)}
            description={multipleAccountsDescription}
            additionalInfo={multipleAccountsAdditionalInfo}
            headline={multipleAccountsHeadline}
            titleCP={multipleAccountsCardTitleCP}
            descriptionCP={multipleAccountsCardDescriptionCP}
            buttonTextCP={multipleAccountsCardButtonTextCP}
            titleSP={multipleAccountsCardTitleSP}
            descriptionSP={multipleAccountsCardDescriptionSP}
            buttonTextSP={multipleAccountsCardButtonTextSP}
          />
        </ModalContent>
      </ModalPortal>
    </>
  );
};

export default LoginModal;
